import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import { FaCoins, FaConnectdevelop, FaDiscord, FaGithub, FaMedium, FaTelegram, FaTwitter, FaWallet } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import { isMetaMaskInstalled } from '../../../../config';
import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  const { walletModalHandle, metamaskModalHandle, account, disconnectWalletFromApp } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };

  const substr = (str, n) =>{
    return str.length > n ? str.substr(0, n -1) : str;
  }

  const handleWalletConnect = async () =>{
    if(!isMetaMaskInstalled()){
      metamaskModalHandle();
    }else{
      walletModalHandle();
    }
  }
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" width="60px"/>
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <a href="#home">Home</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#about">About</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#roadmap">Roadmap</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#team">Community</a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="https://twitter.com/luncrisetoken" target="_blank">
            <FaTwitter />
          </a>
          <a href="https://t.me/luncrise" target="_blank">
            <FaTelegram />
          </a>
          <a href="https://medium.com/luncRise" target="_blank">
            <FaMedium />
          </a>
          <a href="https://github.com/luncRise" target="_blank">
            <FaGithub />
          </a>
        </div>
      
          <a href="https://pancakeswap.finance/swap?outputCurrency=0x55f4C3A096323a0c11F5A86420eaA0f4E803a191&chain=eth" target="_blank">
          <Button
            sm
            variant="hovered"
            className="connect_btn"
          >
            <FaCoins />
            Buy $LCR
          </Button>
          </a>
          <a href="https://dapp.luncrise.io" target="_blank">
          <Button
            sm
            variant="hovered"
            className="connect_btn"
          >
            <FaConnectdevelop />
            Open DApp
          </Button>
          </a>
        
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
