import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase",
        subtitle: "01",
        text: ["✅ Website & Smart Contract", <br></br>, "✅ Smart Contract testing", <br></br>, "✅ Reward tracker bot (TG)", <br></br>, "✅ Dextools update", <br></br>, "✅ BSCScan Update", <br></br>, "✅ Second Dex Listing", <br></br>, "✅ Youtube Marketing", <br></br>, "✅ First Giveaway", <br></br>, "✅ First DApp Release"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase",
        subtitle: "02",
        text: ["✅ Website V2.0", <br></br>, "⚠️ Team expansion", <br></br>, "⚠️ Second DApp", <br></br>, "⚠️ Reward Tracker DApp", <br></br>, "⚠️ TG Marketing", <br></br>, "⚠️ First Audit", <br></br>, "⚠️ Second Giveaway", <br></br>, "⚠️ Banner Marketing", <br></br>, "⚠️ 1500 holders"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase",
        subtitle: "03",
        text: ["⚠️ 2.5K Holders", <br></br>, "⚠️ CMC & CG Listing", <br></br>, "⚠️ DApp´s Audit", <br></br>, "⚠️ Influencer Marketing", <br></br>, "⚠️ 3rd Dex Listing", <br></br>, "⚠️ Partnerships", <br></br>, "⚠️ Mega LunC Burn", <br></br>, "⚠️ Cross-Chain Development", <br></br>, "⚠️ 3rd Dex Listing"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase",
        subtitle: "04",
        text: ["⚠️ LuncRise Membership", <br></br>, "⚠️ Referral DApp", <br></br>, "⚠️ Second Audit", <br></br>, "⚠️ Tier-1 Marketing", <br></br>, "⚠️ 5k Holders", <br></br>, "⚠️ 3rd Community Giveaway", <br></br>, "⚠️ Referral Point System", <br></br>, "⚠️ First CEX-Listing", <br></br>, "⚠️ Terra-Chain integration"]
    }
]

export default data;