import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
  FaGithub
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/luncrisetoken",
  },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/luncrise",
  },
  {
    thumb: mediumIcon,
    url: "https://medium.com/@luncrise",
  },
  {
    icon: <FaGithub />,
    url: "https://github.com/LuncRise",
  },
];

export default data;
