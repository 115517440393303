import aboutCardIcon1 from "../../images/icon/hold_usd_reward.png"
import aboutCardIcon2 from "../../images/icon/burn_icon.png"
import aboutCardIcon3 from "../../images/icon/marketing_icon.png"

 const data = {
    aboutDescription1: 'LCR was invented to help the $LUNC rise again. Our contract was implemented in such a way that it automatically burns 1% LunC for each transaction. This, we will make it possible to get the $LunC back. For the holder 4% LunC auto with every swap.',
    aboutDescription2: 'For this you just have to hold LCR. We have 1% buyback and to support LP and reduce the supply. Initial LP is locked for 6 Month and all tax for LP is going to dead wallet.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Rewards to holders",
            text: "Hold LunCRise $LCR get %4 passive income in $LunC in every transaction."
        },
        {
            icon: aboutCardIcon2,
            title: "Auto LunC Burn Fee",
            text: '1% of each transaction will be used for Burn LunC. Auto-Burn'
        },
        {
            icon: aboutCardIcon3,
            title: "Dev & Marketing Fee",
            text: '3% of each transaction will be used for Marketing.'
        }
    ]
}

export default data;