const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Open Pancakeswap"
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Copy $LCR Contract"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Confirm the Transaction"
    },
    {
        num: "4",
        mintColor: "red",
        text: "Receive Your Tokens"
    },
]

export default data;